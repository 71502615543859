import React, { FC } from "react";
import { Link, graphql, PageProps } from "gatsby";

import Layout from "components/Layout";
import Seo from "components/Seo";

import * as scss from "./blog-post.module.scss";

const BlogPostTemplate: FC<PageProps<GatsbyTypes.BlogPostBySlugQuery>> = ({ data, location }) => {
    const { previous, next } = data;
    const post = data.markdownRemark;

    const title = post?.frontmatter?.title ?? "";
    const description = post?.frontmatter?.description || post?.excerpt;
    const html = post?.html ?? "";

    const date = post?.frontmatter?.date;
    const ogp_image = `${data.site?.siteMetadata?.siteUrl}${post?.fields?.slug}thumbnail.png`;

    return (
        <Layout location={location}>
            <Seo
                title={ title }
                description={ description }
                image={ ogp_image }
            />

            <div className="">
                <div className="p-4 border-b border-gray-50">
                    <h1 className="text-2xl py-2">{ title }</h1>
                    <span className="text-sm">{ date }</span>
                </div>

                <article className={ scss.article + " p-4 border-b border-gray-50" } dangerouslySetInnerHTML={{ __html: html }} />

                <nav className="p-4 flex text-sm">
                    <div className="w-1/2 text-left">{ previous && (
                        <Link to={previous.fields?.slug ?? ""}>
                            ← {previous.frontmatter?.title}
                        </Link>
                    )}</div>
                    <div className="w-1/2 text-right">{ next && (
                        <Link to={next.fields?.slug ?? ""}>
                            {next.frontmatter?.title} →
                        </Link>
                    )}</div>
                </nav>
            </div>
        </Layout>
    );
};
export default BlogPostTemplate;


export const query = graphql`
    query BlogPostBySlug (
        $id: String!
        $previousPostId: String
        $nextPostId: String
    ) {
        site {
            siteMetadata {
                title
                siteUrl
            }
        }
        markdownRemark(id: { eq: $id }) {
            id
            excerpt(pruneLength: 160)
            html
            fields {
                slug
            }
            frontmatter {
                title
                date(formatString: "YYYY年MM月DD日")
                description
            }
        }
        previous: markdownRemark(id: { eq: $previousPostId }) {
            fields {
                slug
            }
            frontmatter {
                title
            }
        }
        next: markdownRemark(id: { eq: $nextPostId }) {
            fields {
                slug
            }
            frontmatter {
                title
            }
        }
    }
`;
